import packageInfo from '../../package.json';

export const environment = {
  version: `staging-${packageInfo.version}`,
  production: false,
  url_api: 'https://test.dashboard.api.ultrapay.digital/api/v1',
  auth0: {
    domain: "test-ultrapay.eu.auth0.com",
    clientId: "ayRItzSqoLCTT1k4BqVnkxvMMIHtvhYC",
    audience: "https://test-ultrapay.eu.auth0.com/api/v2/",
  }
};
